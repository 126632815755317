import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MessagingService } from './shared/services/messaging-service/messaging.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'taskopadnewversion';

    constructor(public messagingService: MessagingService,private http: HttpClient,private renderer: Renderer2, private el: ElementRef) {
        
    }

    ngOnInit(): void {
        this.messagingService.requestPermission();
        let onFirstClick = async () => {
            try {
                // const response = await firstValueFrom(this.http.get<any>('https://ip.nf/me.json'));
                // localStorage.setItem('userIp', response.ip);

              const url:string = 'https://api.ipify.org?format=json';
              const response = await fetch(url, {
                  method: 'GET',
              });
              const data:any = await response.json();
              localStorage.setItem('userIp', data.ip);

              } catch (error) {
                console.error(error);
              }

            document.removeEventListener("click", onFirstClick);
          };
          document.addEventListener("click", onFirstClick);
    }

    loadClarityScript() {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "nbpjvumbmc");
        `;
        this.renderer.appendChild(this.el.nativeElement, script);
      }
}
