import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Injector,
} from '@angular/core';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import * as moment from 'moment';
import { FREQUENCY_FOR_RECURRING } from '../../global/constants';
import { TasksService } from '../../services/task-service/tasks.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api-service/api.service';
import { TimeSheetService } from '../../services/time-sheet-service/time-sheet.service';
import { UtilsService } from '../../services/utils-service/utils.service';

@Component({
    selector: 'app-task-due-date-common',
    templateUrl: './task-due-date-common.component.html',
    styleUrls: ['./task-due-date-common.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        DefaultMatCalendarRangeStrategy,
        MatRangeDateSelectionModel,
    ],
})
export class TaskDueDateCommonComponent implements OnInit, OnChanges {
    minDueDate: any;
    selectedDueDate: Date | null;
    monthlyRecurringDate: any;
  @Input() isSubTask: boolean;
  @Input() task_id: any;
  @Input() dueDateData: any;
  @Input() isTimeSheetSetting: boolean;
  @Input() isPrimaryTask: boolean;
  @Output('selectedDate') selectedDate = new EventEmitter();
  dateRange: DateRange<Date>;
  startDate: any;
  endDate: any;
  date: any;
  timeSheetoffStartDate: any;
  isTaskRecurring = false;
  selectedOnDay: any;
  customOnDay: any;
  recurringDate: any;
  recurringDateSelection: any;
  startTime: string;
  endTime: string;
  estHour: any;
  estMin: any;
  todayDate: any = new Date();
  frequencyConst: any = FREQUENCY_FOR_RECURRING;
  inValidStartEndTime = false;
  public timeSheetService: TimeSheetService;

  frequency: any = [
      {
          name: 'Custom',
          value: 'custom',
      },
      {
          name: 'Weekly',
          value: 'weekly',
      },
      {
          name: 'Monthly',
          value: 'monthly',
      },
      {
          name: 'Quarterly',
          value: 'quarterly',
      },
      {
          name: 'Half Yearly',
          value: 'halfyearly',
      },
      {
          name: 'Yearly',
          value: 'yearly',
      },
  ];

  weekDays = [
      {
          name: 'Sunday',
          sortName: 'S',
          isCheck: false,
      },
      {
          name: 'Monday',
          sortName: 'M',
          isCheck: false,
      },
      {
          name: 'Tuesday',
          sortName: 'T',
          isCheck: false,
      },
      {
          name: 'Wednesday',
          sortName: 'W',
          isCheck: false,
      },
      {
          name: 'Thursday',
          sortName: 'T',
          isCheck: false,
      },
      {
          name: 'Friday',
          sortName: 'F',
          isCheck: false,
      },
      {
          name: 'Saturday',
          sortName: 'S',
          isCheck: false,
      },
  ];
  numbers = Array(28)
      .fill(1)
      .map((_x, index) => index + 1);
  selectedFrequency = '';
  selectedWeekDays: any = [];

  // project data
  @Input() maxDateProject: any;
  @Input() minDateProject: any;

  @Input() rmax: any;
  @Input() rmin: any;
  @Input() isRecurringOn = false;
  @Input() isRecurringParantId = false;
  @Input() serviceRecurring: boolean;
  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private taskService: TasksService,
    private toastrService: ToastrService,
    private injector: Injector,
    public translate: TranslateService,
    private apiService: ApiService,
    public utilsService: UtilsService
  ) {
      translate.setDefaultLang('en');
      this.timeSheetService = injector.get<TimeSheetService>(TimeSheetService);
  }

  ngOnInit() {
      this.minDueDate = new Date();
  }

  ngOnChanges(change?: any) {
      this.minDueDate = new Date();
      if (
          this.dueDateData?.endDate &&
      this.isTimeSheetSetting &&
      this.dueDateData?.isUpdate
      ) {
          const todayDate = this.dayStart(new Date());
          this.startDate = this.dueDateData.startDate
              ? moment(this.dueDateData?.startDate).toDate()
              : todayDate;
          this.endDate = moment(this.dueDateData?.endDate).toDate();
          this.startTime = moment(this.startDate).format('HH:mm')
              ? moment(this.startDate).format('HH:mm')
              : '00:00';
          this.endTime = moment(this.endDate).format('HH:mm')
              ? moment(this.endDate).format('HH:mm')
              : '00:00';
          this.dateRange = new DateRange(this.startDate, this.endDate);
          if (this.dueDateData?.time_sheet_estimated_hr) {
              const estimatedTime =
          this.dueDateData?.time_sheet_estimated_hr?.split(':');
              this.estHour = estimatedTime?.[0];
              this.estMin = estimatedTime?.[1];
          }
      } else if (!this.isTimeSheetSetting && this.dueDateData?.endDate) {
          this.selectedDueDate = moment(this.dueDateData?.endDate).toDate();
          this.date = this.selectedDueDate;
          this.timeSheetoffStartDate = moment(this.dueDateData.startDate).toDate();
      }
      
      if(!this.dueDateData?.isTaskRecurring){
        this.resetAll()
      }

      if(!this.dueDateData?.isTaskRecurring){
        this.isTaskRecurring = false
      }

      if (this.dueDateData?.isTaskRecurring) {
          this.isTaskRecurring = JSON.parse(
              JSON.stringify(this.dueDateData?.isTaskRecurring)
          );
          this.selectedFrequency = this.dueDateData?.frequency;
          this.selectedWeekDays = this.dueDateData?.weekDays;
          this.selectedOnDay = this.dueDateData?.onSelectedDay;
          this.monthlyRecurringDate = moment(
              this.dueDateData?.onSelectedDay
          ).toDate();
          this.customOnDay = this.dueDateData?.customOnDay;
          this.recurringDateSelection = this.dueDateData?.recurringDate
              ? moment(this.dueDateData?.recurringDate).toDate()
              : null;

          
            if(this.dueDateData?.frequency === 'yearly' ||
            this.dueDateData?.frequency === 'halfyearly' ||
            this.dueDateData?.frequency === 'quarterly'){
                this.recurringDate = this.recurringDateSelection
            }


          if (this.selectedWeekDays?.length > 0) {
              this.weekDays = this.weekDays.map((day) => {
                  day.isCheck = this.selectedWeekDays?.some(
                      (selectedDay: any) => selectedDay.name === day.name
                  );
                  return day;
              });
          }
      }
      const currentDate = new Date().toISOString().split('T')[0];
      if (this.maxDateProject) {
          const givenDate = new Date(this.maxDateProject)
              .toISOString()
              .split('T')[0];
          const date = givenDate < currentDate;
          if (date) {
              this.maxDateProject = new Date().setDate(new Date().getDate() - 1);
              this.maxDateProject = new Date(this.maxDateProject);
              this.rmax = this.maxDateProject;
          }
      }
      if (this.minDateProject) {
          const givenDate = new Date(this.minDateProject)
              .toISOString()
              .split('T')[0];
          const minDate = givenDate < currentDate;
          if (minDate) {
              this.minDateProject = new Date();
              this.rmin = new Date();
          }
      }
      if (this.isTaskRecurring && this.dueDateData?.isUpdate) {
        if(this.dueDateData?.frequency === 'yearly' ||
            this.dueDateData?.frequency === 'halfyearly' ||
            this.dueDateData?.frequency === 'quarterly') {
            this.minDateProject = new Date(this.dueDateData?.recurringDate + 24 * 60 * 60 * 1000)
        }      

        if(this.dueDateData?.frequency === 'monthly'){
            this.minDateProject = new Date(
                this.dueDateData?.onSelectedDay + 24 * 60 * 60 * 1000
            )
        }

        if(this.dueDateData?.frequency === 'weekly'){
            this.minDateProject = this.getNextDayOccurrence(
                this.dueDateData?.weekDays[0]?.name
            )
        }
      }

      if (
          change?.maxDateProject?.currentValue !==
        change?.maxDateProject?.previousValue &&
      !this.dueDateData?.isUpdate
      ) {
          this.resetAll();
          this.resetOnChangeProject();
          this.emitDataObj();
      }
  }

  dayStart(date: any) {
      date = date ? date : moment();
      return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  onChangetoggle(event: any) {
      this.isTaskRecurring = event.checked;
      if (!this.isTaskRecurring) {
          this.resetAll();
      }
      this.emitDataObj();
  }

  resetOnFrequencyChange() {
      this.weekDays.forEach((data: any) => {
          data.isCheck = false;
      });
      this.selectedWeekDays = [];
      this.selectedOnDay = '';
      this.customOnDay = '';
      this.recurringDate = null;
      this.minDateProject = this.rmin ? this.rmin : new Date();
      this.emitDataObj();
  }

  resetAll() {
      this.weekDays.forEach((data: any) => {
          data.isCheck = false;
      });
      this.selectedFrequency = '';
      this.selectedWeekDays = [];
      this.selectedOnDay = '';
      this.customOnDay = '';
      this.recurringDate = null;
      this.minDateProject = this.rmin ? this.rmin : new Date();
  }

  selectFrequency(data: any) {
      this.selectedFrequency = data.value;

      this.resetOnFrequencyChange();
  }

  selectOnDay(data: any) {
      this.selectedOnDay = data.value;
      this.emitDataObj();
  }

  customSelectedDay(data: any) {
      this.customOnDay = data;
      this.emitDataObj();
  }

  selectWeekDays(event: any, day: any) {
      this.resetOnChangeProject();
      if (event) {
          this.selectedWeekDays = [];
          this.weekDays.forEach((ele: any) => {
              if (ele.name === day.name) {
                  ele.isCheck = true;
                  this.selectedWeekDays.push(day);
              } else {
                  ele.isCheck = false;
              }
          });
          const nextOccuranceDay = this.getNextDayOccurrence(day.name);
          this.minDateProject = nextOccuranceDay;
      } else {
          this.selectedWeekDays = [];
          this.minDateProject = this.rmin ? this.rmin : new Date();
      }
      this.emitDataObj();
  }

  getNextDayOccurrence(dayName: string): Date {
      const daysOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
      ];
      const currentDay = new Date().getDay();
      const targetDayIndex = daysOfWeek.indexOf(dayName);
      let daysUntilTargetDay = targetDayIndex - currentDay;
      if (daysUntilTargetDay < 0) {
          daysUntilTargetDay += 7;
      }
      let nextOccurrenceDate = new Date();
      nextOccurrenceDate.setDate(
          nextOccurrenceDate.getDate() + daysUntilTargetDay
      );
      if (this.rmin && nextOccurrenceDate.getTime() < this.rmin?.getTime()) {
          nextOccurrenceDate = this.rmin;
      }
      return nextOccurrenceDate;
  }

  async taskDueDate(date: any) {
      this.timeSheetoffStartDate = await this.utilsService.setCurrentDateTime(date);
      this.date = await this.utilsService.setCurrentDateTime(date);
      this.emitDataObj();
  }

  onStartTimeClear() {
      this.startTime = '00:00';
      this.setStartTime();
  }

  onEndTimeClear() {
      this.endTime = '00:00';
      this.setEndTime();
  }

  openStartTimeFromIcon(timepickerstart: { open: () => void }) {
      timepickerstart.open();
  }

  openEndTimeFromIcon(timepickerend: { open: () => void }) {
      timepickerend.open();
  }

  setStartTime() {
      if (
          this.endTime < this.startTime &&
      moment(this.startDate).startOf('d').unix() ==
        moment(this.endDate).startOf('d').unix()
      ) {
          this.apiService.showError(this.translate.instant('END_TIME_INVALID'));
          this.inValidStartEndTime = true;
      } else {
          const date =
        moment(this.startDate).format('MM/DD/YYYY') +
        ' ' +
        (this.startTime ? this.startTime + ':00' : '00:00:00');
          const startTimeAddInStartDate = new Date(date);
          this.startDate = startTimeAddInStartDate;
          this.inValidStartEndTime = false;
      }
      this.emitDataObj();
  }

  setEndTime() {
      if (
          this.endTime < this.startTime &&
      moment(this.startDate).startOf('d').unix() ==
        moment(this.endDate).startOf('d').unix()
      ) {
          this.apiService.showError(this.translate.instant('END_TIME_INVALID'));
          this.inValidStartEndTime = true;
      } else {
          const date =
        moment(this.endDate).format('MM/DD/YYYY') +
        ' ' +
        (this.endTime ? this.endTime + ':00' : '00:00:00');
          const endTimeAddInEndDate = new Date(date);
          this.endDate = endTimeAddInEndDate;
          this.inValidStartEndTime = false;
      }
      this.emitDataObj();
  }

  async rangeTaskDueDate(selectedDate: Date) {
      const selection = this.selectionModel.selection,
          dateRange = this.selectionStrategy.selectionFinished(
              selectedDate,
              selection
          );

      this.selectionModel.updateSelection(dateRange, this);
      this.dateRange = new DateRange<Date>(dateRange.start, dateRange.end);

      if (this.selectionModel.isComplete()) {
          this.endDate = await this.utilsService.setCurrentDateTime(dateRange.end);
          this.startDate =  await this.utilsService.setCurrentDateTime(dateRange.start);
          this.emitDataObj();
      }
  }

  emitDataObj() {
      const data: any = {
          frequency: this.selectedFrequency,
          startTime: this.startTime,
          startDate: this.startDate,
          endDate: this.endDate,
          endTime: this.endTime,
          isTaskRecurring: this.isTaskRecurring,
          weekDays: this.selectedWeekDays,
          recurringDate: this.recurringDate,
          onSelectedDay: this.selectedOnDay,
          customOnDay: this.customOnDay,
          inValidStartEndTime: this.inValidStartEndTime,
      };

      if (this.estHour) {
          this.estMin = this.estMin || '00';
          data['time_sheet_estimated_hr'] = `${this.estHour}:${this.estMin}`;
      }

      if (this.isTimeSheetSetting) {
          this.selectedDate.emit(data);
      } else {
          delete data['startTime'];
          delete data['endDate'];
          delete data['endTime'];
          delete data['inValidStartEndTime'];
          data['startDate'] = this.timeSheetoffStartDate;
          data['taskDueDate'] = this.date;
          data['taskstartDate'] = this.timeSheetoffStartDate;
          this.selectedDate.emit(data);
      }
  }

  async onStartEndTimeChange() {
      this.estHour = null;
      this.estMin = null;
      const requestData: any = {
          start_date: new Date(this.startDate).getTime(),
          due_date: new Date(this.endDate).getTime(),
      };

      if (this.startDate && this.startTime) {
          const [startHours, startMinutes] = this.startTime.split(':');
          const startDateTime = moment(this.startDate)
              .startOf('day')
              .add(Number(startHours), 'hours')
              .add(Number(startMinutes), 'minutes');
          const startTimestamp = startDateTime.valueOf();
          requestData['start_date'] = startTimestamp;
      }

      if (this.endDate && this.endTime) {
          const [endHours, endMinutes] = this.endTime.split(':');
          const endDateTime = moment(this.endDate)
              .startOf('day')
              .add(Number(endHours), 'hours')
              .add(Number(endMinutes), 'minutes');
          const endTimestamp = endDateTime.valueOf();
          requestData['due_date'] = endTimestamp;
      }

      (await this.timeSheetService.getEstimatedTime(requestData)).subscribe(
          (res: any) => {
              if (res?.code === 200) {
                  const workingHours = res?.result?.working_hours?.split(':');
                  this.estHour = workingHours[0];
                  this.estMin = workingHours[1];
                  this.emitDataObj();
              }
          }
      );
  }

  async onEstHourMinChange() {
      this.startTime = null;
      this.endTime = null;
      this.emitDataObj();
  }

  async monthlyReccuringDate(event: any) {
      this.minDateProject = new Date(event.getTime() + 24 * 60 * 60 * 1000);
      this.resetOnChangeProject();
      this.selectedOnDay = await this.utilsService.setCurrentDateTime(event);
      this.emitDataObj();
  }
  async recurringFrequencyDate(event: any) {
      this.minDateProject = new Date(event.getTime() + 24 * 60 * 60 * 1000);
      this.resetOnChangeProject();
      this.recurringDate = await this.utilsService.setCurrentDateTime(event);
      this.emitDataObj();
  }

  resetOnChangeProject() {
      this.date = null;
      this.selectedDueDate = null;
      this.startDate = null;
      this.startTime = null;
      this.endDate = null;
      this.endTime = null;
      this.dateRange = null;
      this.inValidStartEndTime = false;
  }
}
