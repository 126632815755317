import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';
import * as CryptoJS from 'crypto-js';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ServiceManagementService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any = [];
    userId: any;
    companyId: any;
    searchParamData = new BehaviorSubject<any>('');
    setSearchParamData(searchData: any) {
        this.searchParamData.next(searchData);
    }

    constructor(
    public userService: UserDetailsService,
    private apiService: ApiService
    ) {
        this.userId = this.userService.userId;
    }

    convertObjToParams(object: any) {
        let params = new HttpParams();
        Object.keys(object).forEach((key) => {
            if (object[key] != '') {
                params = params.set(key, object[key]);
            }
        });
        return params;
    }

    async getServiceList(companyId: any): Promise<Observable<any>> {
        const obj: any = {
            companyId: companyId,
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?${params}&limit=10&page=1`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
    async getClientListFromService(Id: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.GET_CLIENT_LIST_FROM_SERVICE}`+Id;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
    
    async getRegularServiceList(companyId: any,page?:number,limit?:number, search?:any): Promise<Observable<any>> {
        
        let url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?companyId=${companyId}&limit=${limit}&page=${page}&type=simple`;
        if(search){
            url += `&searchString=${search}`
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getRegularServiceListForMaster(companyId: any,page?:number,limit?:number, search?:any): Promise<Observable<any>> {
        
        let url = `${this.apiEndPoint.SERVICE_MANAGEMENT_MASTER}?companyId=${companyId}&limit=${limit}&page=${page}&type=simple`;
        if(search){
            url += `&searchString=${search}`
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getRecurringServiceList(companyId: any,page?:number,limit?:number, search?:any): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?companyId=${companyId}&limit=${limit}&page=${page}&type=recurring`;
        if(search){
            url += `&searchString=${search}`
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getRecurringServiceListForMaster(companyId: any,page?:number,limit?:number, search?:any): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.SERVICE_MANAGEMENT_MASTER}?companyId=${companyId}&limit=${limit}&page=${page}&type=recurring`;
        if(search){
            url += `&searchString=${search}`
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getServiceListForClient(companyId: any): Promise<Observable<any>> {
        const obj: any = {
            companyId: companyId,
            page:1,
            limit:1000
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?${params}&type=recurring`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getAllServiceList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}/list?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getServiceByID(serviceID: any): Promise<Observable<any>> {
        const obj: any = {
            serviceId: CryptoJS.AES.encrypt(
                serviceID?.toString().trim(),
                ''
            ).toString(),
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}/detail?${params}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async addNewService(data: any): Promise<Observable<any>> {
        this.res = [];
        data.company_id = CryptoJS.AES.encrypt(
            data.company_id?.toString().trim(),
            ''
        ).toString();
        data.creator_id = CryptoJS.AES.encrypt(
            data.creator_id?.toString().trim(),
            ''
        ).toString();
        data.user_id = CryptoJS.AES.encrypt(
            data.user_id?.toString().trim(),
            ''
        ).toString();

        await this.apiService
            .post(this.apiEndPoint.SERVICE_MANAGEMENT, { data: data })
            .then((respdata) => {
                this.res.push(respdata);
            });
        return of(this.res);
    }

    async updateService(data: any, serviceID: any): Promise<Observable<any>> {
        this.res = [];
        const obj: any = {
            serviceId: CryptoJS.AES.encrypt(
                serviceID?.toString().trim(),
                ''
            ).toString(),
        };
        data.company_id = CryptoJS.AES.encrypt(
            data.company_id?.toString().trim(),
            ''
        ).toString();
        data.creator_id = CryptoJS.AES.encrypt(
            data.creator_id?.toString().trim(),
            ''
        ).toString();
        data.user_id = CryptoJS.AES.encrypt(
            data.user_id?.toString().trim(),
            ''
        ).toString();
        if (data?.updated_by) {
            data.updated_by = CryptoJS.AES.encrypt(
                data.updated_by?.toString().trim(),
                ''
            ).toString();
        }
        delete data.creator_id;
        delete data.user_id;
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?${params}`;
        await this.apiService.put(url, { data: data }).then((resData) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    async deleteService(serviceID: any): Promise<Observable<any>> {
        this.res = [];
        const obj: any = {
            serviceId: CryptoJS.AES.encrypt(
                serviceID?.toString().trim(),
                ''
            ).toString(),
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?${params}`;
        await this.apiService.delete(url).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async getServiceListExport(companyId: any,type:string): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT_EXPORT}?companyId=${companyId}&type=${type}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
}
